@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css');
@import url("https://use.typekit.net/ctt7ikm.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TASA Orbiter';
  src: url('assets/fonts/woff2/TASAOrbiterVF.woff2');
}

body {
  margin: 0;
  font-family: 'TASA Orbiter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
